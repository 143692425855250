import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

import { LiLinkCard } from "./socialCards";

const Profile = ({ accessToken }) => {
  const { user, isLoading } = useAuth0();

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  return (
    <div>
      <h2>{user.name}</h2>
      <img src={user.picture} alt={user.name} />
      {accessToken && <LiLinkCard accessToken={accessToken} />}
    </div>
  );
};

export default Profile;
