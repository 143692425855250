import React, { useEffect } from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { green, red } from "@mui/material/colors";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import styled from "@emotion/styled";

import Moment from "react-moment";

const LogoStyled = styled.div`
  font-size: 3rem;
`;

const SubHeaderContent = (serviceExp) => {
  if (serviceExp === null) {
    return <Typography>fetching</Typography>;
  } else if (serviceExp < 0) {
    return <Typography color={red[400]}>inactive</Typography>;
  } else {
    return (
      <Typography color={green[400]}>
        Expires:{" "}
        <Moment unix format="YYYY-MM-DD">
          {serviceExp}
        </Moment>
      </Typography>
    );
  }
};

export default function SocialLinkCard({
  name,
  getServiceExp,
  logo,
  linkService,
  unLinkService,
}) {
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [serviceExp, setServiceExp] = React.useState(null);

  useEffect(() => {
    const getServiceExpires = async () => {
      const exp = await getServiceExp();
      setServiceExp(exp);
    };
    getServiceExpires();
  }, [getServiceExp]);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardHeader
        avatar={<LogoStyled>{logo}</LogoStyled>}
        action={
          <IconButton aria-label="settings" onClick={handleOpenUserMenu}>
            <MoreVertIcon />
          </IconButton>
        }
        title={<Typography variant="h5">{name}</Typography>}
        subheader={SubHeaderContent(serviceExp)}
      />
      <Menu
        sx={{ mt: "45px" }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <MenuItem onClick={linkService}>
          <Typography textAlign="center">Activate</Typography>
        </MenuItem>
        <MenuItem
          onClick={async () => {
            handleCloseUserMenu();
            await unLinkService();
            setServiceExp(-1);
            handleCloseUserMenu();
          }}
          // disabled={serviceExp < 0}
          disabled={true}
        >
          <Typography textAlign="center">Unlink</Typography>
        </MenuItem>
      </Menu>
    </Card>
  );
}
