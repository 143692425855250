import React from "react";
import { Outlet } from "react-router-dom";
import Container from "@mui/material/Container";

import AppBar from "../../components/appBar";

const LoggedIn = ({ user, logout, isAuthenticated }) => (
  <div>
    <AppBar user={user} logout={logout} isAuthenticated={isAuthenticated} />
    <Container
      maxWidth="lg"
      sx={{
        paddingTop: 5,
        mb: 2,
      }}
    >
      <Outlet />
    </Container>
  </div>
);

export default LoggedIn;
