const servers = {
  "app.dev.aicurate.io": "dev",
  "app.staging.aicurate.io": "staging",
  "app.aicurate.io": "prod",
};

const config = {
  dev: {
    api_base: "https://46xvwvxwy1.execute-api.eu-west-1.amazonaws.com/dev",
    api_cache: false,
    auth0_domain: "dev--fcvrp4i.eu.auth0.com",
    auth0_clientId: "mqv94ApP93y1vyTzV1KYHvb0snu665Jw",
    auth0_audience: "ckxIn - server",
    auth0_scope: "profile read:messages",
  },
  staging: {
    api_base: "https://46xvwvxwy1.execute-api.eu-west-1.amazonaws.com/staging",
    api_cache: false,
    auth0_domain: "dev--fcvrp4i.eu.auth0.com",
    auth0_clientId: "mqv94ApP93y1vyTzV1KYHvb0snu665Jw",
    auth0_audience: "ckxIn - server",
    auth0_scope: "profile read:messages",
  },
  prod: {
    api_base: "https://46xvwvxwy1.execute-api.eu-west-1.amazonaws.com",
    api_cache: false,
    auth0_domain: "dev--fcvrp4i.eu.auth0.com",
    auth0_clientId: "mqv94ApP93y1vyTzV1KYHvb0snu665Jw",
    auth0_audience: "ckxIn - server",
    auth0_scope: "profile read:messages",
  },
  local: {
    api_base: process.env.REACT_APP_API_BASE,
    api_cache: process.env.REACT_APP_API_CACHE,
    auth0_domain: process.env.REACT_APP_AUTH0_DOMAIN,
    auth0_clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
    auth0_audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    auth0_scope: process.env.REACT_APP_AUTH0_SCOPE,
  },
};

function getConfig() {
  let myEnv = "local";
  const myHost = window.location.hostname;
  if (servers.hasOwnProperty(myHost)) {
    myEnv = servers[myHost];
  }
  return config[myEnv];
}

export default getConfig;
