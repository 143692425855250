import React from "react";

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import { createChat } from "../../api/ckxIn";

const TONES = [
  "neutral",
  "formal",
  "humorous",
  "optimistic",
  "friendly",
  "sassy",
  "dry humour",
  "dark humour",
  "encouraging",
];

function CreatePost({ accessToken }) {
  const navigate = useNavigate();
  const [topic, setTopic] = React.useState("");
  const [tone, setTone] = React.useState("neutral");
  const [creatingChat, setCreatingChat] = React.useState(false);

  const {
    user: { sub: userId },
  } = useAuth0();

  const handleTopicChange = (event) => {
    setTopic(event.target.value);
  };

  const handleToneChange = (event) => {
    setTone(event.target.value);
  };

  const handleCreateChat = async () => {
    setCreatingChat(true);
    const newChat = await createChat(accessToken, userId, {
      topic,
      tone,
    }).finally(() => {
      setCreatingChat(false);
    });
    navigate(`/posts/${newChat._id}`);
  };

  return (
    <Box>
      <Typography variant="h2">Create a post</Typography>
      <Box sx={{ p: 3 }}>
        <FormControl fullWidth noValidate autoComplete="off">
          <Box sx={{ mb: 2 }}>
            <TextField
              fullWidth
              multiline
              rows={4}
              label="What do you want to talk about?"
              variant="outlined"
              value={topic}
              onChange={handleTopicChange}
            />
          </Box>
          <Typography variant="h5">
            What tone would you like the post
          </Typography>
          <RadioGroup value={tone} onChange={handleToneChange} row>
            {TONES.map((t) => (
              <FormControlLabel
                key={`tone-select-${t}`}
                value={t}
                control={<Radio />}
                label={t}
              />
            ))}
          </RadioGroup>
        </FormControl>
        <Button
          variant="outlined"
          disabled={topic.length < 20 || creatingChat}
          onClick={handleCreateChat}
        >
          Next
        </Button>
      </Box>
    </Box>
  );
}

export default CreatePost;
