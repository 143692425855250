import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";

import ContentSearchContainer from "../../containers/contentSearch";
import ContentPreviewList from "../../components/contentPreviewList";
import ContentSearch from "../../components/contentSearch";

import Grid from "@mui/material/Grid";

export default function ContentSearchPage({ accessToken }) {
  const navigate = useNavigate();
  const [filter, setFilter] = useState({});
  const handleView = (content) => {
    navigate(`/content/${content._id}`);
  };

  const handleFilterSubmit = (newFilter) => setFilter(newFilter);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={3}>
        <ContentSearch onSubmit={handleFilterSubmit} />
      </Grid>
      <Grid item xs={12} sm={9}>
        {accessToken && (
          <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
            <ContentSearchContainer accessToken={accessToken} filter={filter}>
              {({ status, content }) => (
                <ContentPreviewList contents={content} onView={handleView} />
              )}
            </ContentSearchContainer>
          </Box>
        )}
      </Grid>
    </Grid>
  );
}
