import React from "react";

import { getContentById } from "../../api/ckxIn";

class ContentByIdContainer extends React.Component {
  state = {
    status: "fetching",
    content: null,
  };

  async getContent() {
    this.setState({ status: "fetching" });
    const { accessToken, id } = this.props;
    const content = await getContentById(accessToken, id);
    this.setState({ content, status: "ready" });
  }

  componentDidMount() {
    this.getContent();
  }

  render() {
    const { children } = this.props;
    if (!children) {
      return null;
    }
    return children(this.state);
  }
}

export default ContentByIdContainer;
