import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

import Typography from "@mui/material/Typography";

import ContentChatContainer from "../../containers/contentChat";
import ContentChat from "../../components/chat";

import { getLinkedInPost } from "../../api/ckxIn";

export default function ContentPage({ accessToken }) {
  const navigate = useNavigate();
  const { contentId } = useParams();

  useEffect(() => {
    const checkIfPosted = async () => {
      const resp = await getLinkedInPost(accessToken, "me", contentId).catch(
        () => {
          console.log("handled error");
        }
      );
      if (resp) {
        navigate(`/content/${contentId}`, { replace: true });
      }
    };
    checkIfPosted();
  });

  const handleTextSelect = (content, idx) => {
    navigate(`${idx - 1}`);
  };

  return (
    <ContentChatContainer
      accessToken={accessToken}
      contentId={contentId}
      childProps={{ handleTextSelect: handleTextSelect }}
    >
      {({ status, contentChat, createChat, newChat }) => {
        if (status !== "ready") {
          return <Typography>Loading....</Typography>;
        }
        if (!contentChat) {
          createChat();
          return <Typography>Loading....</Typography>;
        }
        return (
          <ContentChat
            chat={contentChat}
            onNewChat={newChat}
            onTextSelect={handleTextSelect}
            showTyping={true}
          />
        );
      }}
    </ContentChatContainer>
  );
}
