import React, { useState, useEffect } from "react";

import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import Joyride, { STATUS } from "react-joyride";

import EditPost from "../../components/editPost";

export default function LinkedInCreatePost({
  onSubmit,
  onChange = null,
  disabled = false,
  confirmSubmit = true,
  value = null,
  accessToken,
  userId,
}) {
  const [confirm, setConfirm] = useState(false);
  const [post, setPost] = useState(null);
  const [showFtux, setFtux] = useState(false);

  useEffect(() => {
    const shownFtux = localStorage.getItem("ftux-wizard-editPost");
    if (!shownFtux) {
      setFtux(true);
    }
  }, [setFtux]);

  const handleSubmitPost = async (newPost) => {
    if (!confirmSubmit) {
      onSubmit(post);
      return;
    }
    setPost(newPost);
    setConfirm(true);
  };

  const handleConfirmSubmit = async () => {
    onSubmit(post);
    setConfirm(false);
  };

  return (
    <>
      <Joyride
        continuous={true}
        run={showFtux}
        callback={(data) => {
          const { status } = data;
          if ([STATUS.FINISHED].includes(status)) {
            localStorage.setItem("ftux-wizard-editPost", true);
          }
        }}
        steps={[
          {
            target: "#editPostRoot",
            content: "make some last minute manual edits",
          },
          {
            target: ".changePostImage",
            content: "add an image to make your post pop",
          },
          {
            target: ".submitPost",
            content: "finally post it to LinkedIn",
          },
        ]}
      />

      <Box id="editPostRoot">
        <EditPost
          value={value}
          onChange={onChange}
          onSubmit={handleSubmitPost}
          disabled={disabled || confirm}
          accessToken={accessToken}
          userId={userId}
        />
      </Box>

      <Dialog open={confirm} onClose={() => setConfirm(false)}>
        <DialogTitle>{"Post to your linkedIn profile?"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            This action will post the content straight to your LinkedIn profile
            and be publicly visable.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirm(null)} disabled={disabled}>
            Cancel
          </Button>
          <Button
            onClick={handleConfirmSubmit}
            autoFocus
            color="success"
            variant="contained"
            disabled={disabled}
          >
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
