import React, { useState } from "react";

import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import SubmitIcon from "@mui/icons-material/KeyboardReturn";
import InputBase from "@mui/material/InputBase";
import Fab from "@mui/material/Fab";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import Slide from "@mui/material/Slide";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { green, amber } from "@mui/material/colors";
import { styled } from "@mui/material/styles";

import "./bounce.css";

const TypingRoot = styled("div")`
  width: 70px;
  text-align: center;
`;
const TypingDot = styled("div")`
  width: 0.5em;
  height: 0.5em;
  background-color: #333;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
`;

const PaperUserChat = styled(Paper)`
  padding: 10px;
  background-color: ${green[100]};
  text-align: right;
`;

const PaperUserPendChat = styled(Paper)`
  padding: 10px;
  background-color: ${amber[100]};
`;

const PaperAssistantChat = styled(Paper)`
  padding: 10px;
`;

const UserChat = ({ content }) => {
  return (
    <Slide direction="left" in={true} mountOnEnter unmountOnExit>
      <Box
        sx={{ width: "fit-content", maxWidth: "75%", ml: "auto", mb: 2, mr: 2 }}
      >
        <PaperUserChat>
          <Typography>{content}</Typography>
        </PaperUserChat>
      </Box>
    </Slide>
  );
};
const UserPendChat = ({ content }) => {
  return (
    <Slide direction="left" in={true} mountOnEnter unmountOnExit>
      <Box
        sx={{ width: "fit-content", maxWidth: "75%", ml: "auto", mb: 2, mr: 2 }}
      >
        <PaperUserPendChat>
          <Typography>{content}</Typography>
        </PaperUserPendChat>
      </Box>
    </Slide>
  );
};
const AssistantChat = ({ content, onClickSocial = null }) => {
  const handleTextSelect = () => {
    if (onClickSocial !== null) {
      onClickSocial(content);
    }
  };

  return (
    <Slide direction="right" in={true} mountOnEnter>
      <Box sx={{ maxWidth: "75%", ml: 2, mb: 2 }}>
        <PaperAssistantChat>
          <>
            <TextField
              value={content}
              multiline
              minRows={2}
              maxRows={15}
              fullWidth
              variant="standard"
              InputProps={{
                readOnly: true,
                disableUnderline: true,
              }}
            />
            {onClickSocial !== null && (
              <>
                <Divider sx={{ m: 2 }} />
                <Fab size="small" onClick={handleTextSelect}>
                  <LinkedInIcon />
                </Fab>
              </>
            )}
          </>
        </PaperAssistantChat>
      </Box>
    </Slide>
  );
};

const AssistantTyping = () => (
  <Slide direction="right" in={true} mountOnEnter unmountOnExit>
    <Box sx={{ maxWidth: "75%", ml: 2, mb: 2 }}>
      <PaperAssistantChat>
        <TypingRoot>
          <TypingDot sx={{ animationDelay: "-0.32s" }} />
          <TypingDot sx={{ animationDelay: "-0.16s" }} />
          <TypingDot />
        </TypingRoot>
      </PaperAssistantChat>
    </Box>
  </Slide>
);

const Message = ({ role, content, onClickSocial = null }) => {
  if (role === "system") {
    return null;
  }
  return (
    <>
      {role === "user" && <UserChat content={content} />}
      {role === "user-pend" && <UserPendChat content={content} />}
      {role === "assistant" && (
        <AssistantChat content={content} onClickSocial={onClickSocial} />
      )}
    </>
  );
};

function NewCommentInput({ onSubmit, disabled }) {
  const [value, setValue] = useState("");

  const handleChange = (evt) => setValue(evt.target.value);
  const handleSubmit = () => {
    if (onSubmit) {
      onSubmit(value);
      setValue("");
    }
  };

  const handleKeyPress = (evt) => {
    if (evt.key === "Enter") {
      evt.preventDefault();
      handleSubmit();
    }
  };

  return (
    <Paper
      component="form"
      sx={{ p: "2px 4px", display: "flex", alignItems: "center" }}
    >
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder="write comment to assistant"
        value={value}
        onChange={handleChange}
        onKeyDown={handleKeyPress}
        disabled={disabled}
      />
      <Divider sx={{ height: 28 }} />
      <IconButton
        onClick={handleSubmit}
        color="primary"
        sx={{ p: "10px" }}
        aria-label="directions"
        disabled={disabled}
      >
        <SubmitIcon />
      </IconButton>
    </Paper>
  );
}

function Chat({
  chat: { messages = [] },
  onNewMessage = null,
  onClickSocial = null,
  disabled = false,
}) {
  const [newChat, setNewChat] = useState(null);

  const handleSubmit = async (value) => {
    if (onNewMessage === null) {
      return;
    }
    setNewChat(value);
    await onNewMessage(value).catch((err) => console.log(err));
    setNewChat(null);
  };
  const messagesRev = messages.toReversed();
  return (
    <Box>
      <Box sx={{ mb: 3 }}>
        {onNewMessage !== null && (
          <NewCommentInput
            onSubmit={handleSubmit}
            disabled={newChat !== null}
          />
        )}
      </Box>
      {newChat && <AssistantTyping />}
      {newChat && <Message role="user-pend" content={newChat} />}
      {messagesRev.map((m) => (
        <Box key={`message-${m._id}`}>
          <Message {...m} onClickSocial={onClickSocial} />
        </Box>
      ))}
    </Box>
  );
}

export default Chat;
