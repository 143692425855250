import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import LinkedInIcon from "@mui/icons-material/LinkedIn";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import ChatContainer from "../../containers/chat";
import ChatComponent from "../../components/chat";
import ContentComponent from "../../components/content";
import CreateLiPostComponent from "../../components/linkedInCreatePost";
import LiPostComponent from "../../components/linkedInPost";

import ListChatLabels from "../../components/listChatLabels";
import { postLinkedin } from "../../api/ckxIn";

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    "aria-controls": `chat-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function ChatToPost({ accessToken, chatId, userId }) {
  const [selTab, setSelTab] = useState(1);
  const [submittingPost, setSubmittingPost] = useState(false);
  const [postText, setPostText] = useState("");

  const handleTabChange = (event, newValue) => setSelTab(newValue);
  const handleTextSelect = (text) => setPostText(text);
  const handleTextChange = (text) => setPostText(text);
  const handleLiPostSubmit = async (postMe) => {
    setSubmittingPost(true);
    await postLinkedin(accessToken, userId, chatId, postMe);
    window.location.reload(false);
  };

  return (
    <Box>
      <ChatContainer accessToken={accessToken} userId={userId} chatId={chatId}>
        {({ status, chat, onNewMessage, error, update }) => {
          if (status === "fetching") {
            return <Typography>Loading....</Typography>;
          }
          if (status === "error") {
            return <Typography>Error: {error.message}</Typography>;
          }

          return (
            <Box>
              <Box sx={{ mb: 2 }}>
                {chat.content && (
                  <Tabs
                    value={selTab}
                    onChange={handleTabChange}
                    aria-label="basic tabs example"
                  >
                    <Tab label="Link Details" {...a11yProps(0)} />
                    <Tab label={<LinkedInIcon />} {...a11yProps(1)} />
                  </Tabs>
                )}
              </Box>
              {chat.content && (
                <TabPanel value={selTab} index={0}>
                  <ContentComponent {...chat.content} />
                </TabPanel>
              )}
              <TabPanel value={selTab} index={1}>
                <Box>
                  {!chat.liPost && (
                    <>
                      <Typography variant="h4" gutterBottom>
                        Create a LinkedIn post
                      </Typography>
                      <CreateLiPostComponent
                        value={postText}
                        onChange={handleTextChange}
                        onSubmit={handleLiPostSubmit}
                        disabled={submittingPost}
                        accessToken={accessToken}
                        userId={userId}
                      />
                    </>
                  )}
                  {chat.liPost && (
                    <>
                      <Typography variant="h4" gutterBottom>
                        Linkedin Post
                      </Typography>
                      <LiPostComponent {...chat.liPost} />
                    </>
                  )}
                  <Box sx={{ mt: 2 }}>
                    <Typography variant="h4" gutterBottom>
                      Labels
                    </Typography>
                    <ListChatLabels
                      labels={chat.labels}
                      onAdd={(newLabel) =>
                        update({ labels: [...chat.labels, newLabel] })
                      }
                      disabled={status === "updating"}
                      onDelete={(delLabel) => {
                        const index = chat.labels.indexOf(delLabel);
                        console.log("here", delLabel, index);
                        if (index > -1) {
                          chat.labels.splice(index, 1);
                          update({ labels: chat.labels });
                        }
                      }}
                    />
                  </Box>
                </Box>
              </TabPanel>
              <Box sx={{ mt: 3 }}>
                <Typography variant="h4" gutterBottom={!chat.content}>
                  AI Assistant
                </Typography>
                {chat.content && (
                  <Typography variant="subtitle2" gutterBottom>
                    Article Title: {chat.content.title}
                  </Typography>
                )}
                <ChatComponent
                  chat={chat}
                  onNewMessage={Boolean(chat.liPost) ? null : onNewMessage}
                  onClickSocial={Boolean(chat.liPost) ? null : handleTextSelect}
                  showTyping={true}
                />
              </Box>
            </Box>
          );
        }}
      </ChatContainer>
    </Box>
  );
}

export default ChatToPost;
