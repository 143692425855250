import React from "react";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { Typography, Button, Box } from "@mui/material";

function Home() {
  const navigate = useNavigate();
  const { isLoading } = useAuth0();
  return (
    <Box className="App">
      <Typography>
        Hello there, welcome to the aicurate beta users page. To get started
        login;
      </Typography>
      <Button
        variant="contained"
        onClick={() => navigate("/posts")}
        disabled={isLoading}
      >
        Login / register
      </Button>
    </Box>
  );
}

export default Home;
