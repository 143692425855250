import React from "react";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import Box from "@mui/material/Box";

import SuggestedContentContainer from "../../containers/suggestedContent";
import ContentPreviewList from "../../components/contentPreviewList";

const Profile = ({ accessToken }) => {
  const navigate = useNavigate();
  const { isLoading } = useAuth0();

  const handleView = (content) => {
    // navigate(`/content/${content._id}`, {
    //   state: { content: JSON.stringify(content) },
    // });
    navigate(`/content/${content._id}`);
  };

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  return (
    <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
      {accessToken && (
        <SuggestedContentContainer accessToken={accessToken}>
          {({ status, content }) => (
            <ContentPreviewList contents={content} onView={handleView} />
          )}
        </SuggestedContentContainer>
      )}
    </Box>
  );
};

export default Profile;
