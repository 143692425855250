import * as React from "react";
import Box from "@mui/material/Box";
import Badge from "@mui/material/Badge";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Popover from "@mui/material/Popover";
import Button from "@mui/material/Button";
import FormHelperText from "@mui/material/FormHelperText";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";

export default function SearchForm({ onSubmit }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [text, setText] = React.useState("");
  const [states, setStates] = React.useState({
    business: false,
    entertainment: false,
    environment: false,
    food: false,
    health: false,
    politics: false,
    science: false,
    sports: false,
    technology: false,
    top: false,
    tourism: false,
    world: false,
  });

  const handleSubmit = () => {
    if (!onSubmit) {
      return;
    }
    const category = Object.keys(states).filter((v) => states[v]);
    onSubmit({ category, q: text });
  };
  const toggleOpen = (event) => {
    if (anchorEl && error) {
      return;
    }
    anchorEl ? setAnchorEl(null) : setAnchorEl(event.currentTarget);
  };
  const handleChange = (event) => {
    setStates({
      ...states,
      [event.target.name]: event.target.checked,
    });
  };

  const numSelected = Object.keys(states).filter((v) => states[v]).length;
  const error = numSelected > 5;

  const handleKeyPress = (evt) => {
    if (evt.key === "Enter") {
      evt.preventDefault();
      handleSubmit();
    }
  };

  return (
    <Box component="form">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            autoComplete="off"
            noValidate
            fullWidth
            value={text}
            onChange={(evt) => setText(evt.target.value)}
            label="Search"
            placeholder="search for articles containing...."
            onKeyDown={handleKeyPress}
          />
        </Grid>
        <Grid item xs={12}>
          <Badge
            badgeContent={numSelected}
            color="primary"
            sx={{ width: "100%" }}
          >
            <Button
              onClick={toggleOpen}
              variant="outlined"
              color={error ? "error" : "primary"}
              fullWidth
            >
              Categories
            </Button>
          </Badge>
          <Popover
            anchorEl={anchorEl}
            open={anchorEl !== null}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            onClose={toggleOpen}
          >
            <FormControl
              error={error}
              component="fieldset"
              sx={{ m: 3 }}
              variant="standard"
            >
              <FormGroup>
                {Object.keys(states).map((s) => (
                  <FormControlLabel
                    key={`chkbox-${s}`}
                    sx={{
                      textTransform: "capitalize",
                    }}
                    control={
                      <Checkbox
                        checked={states[s]}
                        onChange={handleChange}
                        name={s}
                      />
                    }
                    label={s}
                  />
                ))}
                <FormHelperText>Maximum of 5</FormHelperText>
              </FormGroup>
              <Button onClick={toggleOpen} disabled={error}>
                Close
              </Button>
            </FormControl>
          </Popover>
        </Grid>
        <Grid item xs={12}>
          <Button onClick={handleSubmit} variant="contained" disabled={error}>
            Update
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}
