import React, { useState, useEffect } from "react";
import ImageUploading from "react-images-uploading";

import ButtonGroup from "@mui/material/ButtonGroup";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Green from "@mui/material/colors/green";
import Grey from "@mui/material/colors/grey";
import Typography from "@mui/material/Typography";

import Gallery from "./gallery";

import Resizer from "react-image-file-resizer";

function ImageResizer({ img: { file }, onResized }) {
  const [isResizing] = useState(true);

  useEffect(() => {
    Resizer.imageFileResizer(
      file,
      1920,
      1920,
      "JPEG",
      100,
      0,
      onResized,
      "base64"
    );
  });

  if (isResizing) {
    return (
      <Box>
        <Typography>Resizing....</Typography>
      </Box>
    );
  }
}

function ImageSelect({ accessToken, userId, onSubmit, onCancel }) {
  const [images, setImages] = useState([]);
  const [resizedImg, setResizedImg] = useState(null);
  const [openGallery, setOpenGallery] = useState(false);
  const maxNumber = 1;

  const onChange = (imageList, addUpdateIndex) => {
    setImages(imageList);
  };

  const handleSubmit = () => {
    if (onSubmit) {
      const subImg = resizedImg ? resizedImg : images[0]["data_url"];
      // onSubmit(images[0]["data_url"]);
      onSubmit(subImg);
    }
  };

  const handleGallerySel = ({ url }) => {
    if (onSubmit) {
      onSubmit(url);
    }
  };

  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    }
  };

  return (
    <Box>
      <Box sx={{ mb: 3 }}>
        <Button
          variant="contained"
          fullWidth
          onClick={() => setOpenGallery(true)}
        >
          Select from AI gallary
        </Button>
      </Box>
      <ImageUploading
        multiple
        value={images}
        onChange={onChange}
        maxNumber={maxNumber}
        dataURLKey="data_url"
      >
        {({
          imageList,
          onImageUpload,
          onImageRemove,
          isDragging,
          dragProps,
        }) => {
          const selImg = imageList.length === 0 ? null : imageList[0];

          if (
            selImg !== null &&
            selImg.file.size > 3145728 &&
            resizedImg === null
          ) {
            return <ImageResizer img={selImg} onResized={setResizedImg} />;
          }

          return (
            <Box
              sx={{
                width: 300,
                height: 300,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                p: 3,
                borderRadius: 5,
                borderStyle: isDragging ? "solid" : "dashed",
                borderColor: isDragging ? Green[500] : Grey[500],
              }}
              {...dragProps}
            >
              {imageList.length === 0 && (
                <Button
                  variant={isDragging ? "contained" : "outlined"}
                  onClick={onImageUpload}
                  disabled={isDragging}
                >
                  Click or Drop here
                </Button>
              )}
              {imageList.length > 0 && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <img
                    src={resizedImg ? resizedImg : imageList[0]["data_url"]}
                    alt=""
                    width="100%"
                  />
                  <Button
                    onClick={() => onImageRemove(0)}
                    variant="outlined"
                    sx={{ m: 1 }}
                  >
                    Remove
                  </Button>
                </Box>
              )}
              {imageList.length > 0 && (
                <ButtonGroup variant="contained" color="success" fullWidth>
                  <Button color="warning" onClick={handleCancel}>
                    Cancel
                  </Button>
                  <Button color="success" onClick={handleSubmit}>
                    Submit
                  </Button>
                </ButtonGroup>
              )}
            </Box>
          );
        }}
      </ImageUploading>
      <Gallery
        open={openGallery}
        accessToken={accessToken}
        userId={userId}
        onClose={() => setOpenGallery(false)}
        onClick={handleGallerySel}
      />
    </Box>
  );
}

export default ImageSelect;
