import React from "react";

import {
  getContentChatByContentId,
  createContentChat,
  createContentChatComment,
} from "../../api/ckxIn";

class ContentByIdContainer extends React.Component {
  state = {
    status: "fetching",
    contentChat: null,
  };

  async getContentChat() {
    this.setState({ status: "fetching" });
    const { accessToken, contentId } = this.props;
    const contentChat = await getContentChatByContentId(
      accessToken,
      contentId
    ).catch((err) => {
      if (err.response.status !== 410) {
        throw err;
      }
      return null;
    });
    this.setState({ status: "ready", contentChat });
  }

  componentDidMount() {
    this.getContentChat();
  }

  createChat = async () => {
    const { accessToken, contentId } = this.props;
    this.setState({ status: "fetching" });
    const contentChat = await createContentChat(accessToken, contentId);
    this.setState({ status: "ready", contentChat });
  };

  newChat = async (message) => {
    const { accessToken, contentId } = this.props;
    const { contentChat } = this.state;
    const newContentChat = await createContentChatComment(
      accessToken,
      contentId,
      contentChat._id,
      message
    );
    this.setState({ contentChat: newContentChat });
  };

  render() {
    const { children, childProps } = this.props;
    if (!children) {
      return null;
    }
    return children({
      ...childProps,
      ...this.state,
      createChat: this.createChat,
      newChat: this.newChat,
    });
  }
}

export default ContentByIdContainer;
