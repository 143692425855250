import React from "react";
import axios from "axios";

import LinkedInIcon from "@mui/icons-material/LinkedIn";

import SocialLinkCard from "../../components/socialLinkCard";

const apiBase = process.env.REACT_APP_API_BASE;

const getSocialExpires = async (service, accessToken) => {
  const ep = `${apiBase}/${service}/check-status`;
  const resp = await axios.get(ep, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  if (resp.status === 204) {
    return -1;
  } else {
    return resp.data.exp;
  }
};

const linkSocialService = async (service, accessToken) => {
  const ep = `${apiBase}/${service}/get-link`;
  const {
    data: { url },
  } = await axios.get(ep, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  window.location.replace(url);
};
const unlinkSocialService = async (service, accessToken) => {
  const ep = `${apiBase}/${service}`;
  await axios.delete(ep, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const LiLinkCard = (props) => (
  <SocialLinkCard
    name="LinkedIn"
    logo={<LinkedInIcon sx={{ fontSize: "inherit", color: "#0072b1" }} />}
    unLinkService={() => unlinkSocialService("linkedin", props.accessToken)}
    linkService={() => linkSocialService("linkedin", props.accessToken)}
    getServiceExp={() => getSocialExpires("linkedin", props.accessToken)}
    {...props}
  />
);
