import React, { useState, useEffect, useCallback } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Fade from "@mui/material/Fade";
import Skeleton from "@mui/material/Skeleton";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";

import { Controlled as ControlledZoom } from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

import { listAiGenImage, aiGenImage, userLimits } from "../../api/ckxIn";

const MySkeleton = () => (
  <Grid item xs={12} sm={6} md={3}>
    <Box sx={{ height: 240, width: "100%" }}>
      <Skeleton variant="rectangular" width="100%" height="100%" />
    </Box>
  </Grid>
);

const MySkeletons = () => (
  <Grid container spacing={2} sx={{ mb: 1 }}>
    <MySkeleton />
    <MySkeleton />
    <MySkeleton />
    <MySkeleton />
  </Grid>
);

const Image = ({ prompt, url, onClick }) => {
  const [hovered, setHovered] = useState(false);
  const [isZoomed, setIsZoomed] = useState(false);

  const handleZoomChange = useCallback(
    (shouldZoom) => setIsZoomed(shouldZoom),
    []
  );

  const handleClick = () => {
    if (onClick) {
      onClick({ prompt, url });
      return;
    }
    setIsZoomed(true);
  };

  return (
    <Box
      sx={{
        position: "relative",
        textAlign: "center",
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <ControlledZoom isZoomed={isZoomed} onZoomChange={handleZoomChange}>
        <img width="100%" src={url} alt={prompt} />
      </ControlledZoom>
      <Fade in={hovered}>
        <Box
          sx={{
            p: 2,
            position: "absolute",
            top: 0,
            backgroundColor: "rgba(255, 255, 255, 0.9)",
            height: "100%",
            width: "100%",
            cursor: "zoom-in",
          }}
          onClick={handleClick}
        >
          <Typography>{prompt}</Typography>
        </Box>
      </Fade>
    </Box>
  );
};

const ImageList = ({ prompt, images, onClick }) => (
  <Grid container spacing={2}>
    {images.map((url) => (
      <Grid key={url} item xs={12} sm={6} md={3}>
        <Image prompt={prompt} url={url} onClick={onClick} />
      </Grid>
    ))}
  </Grid>
);

function Images({ accessToken, userId, showSkeletons, onClick }) {
  const [isLoading, setIsLoading] = useState(false);
  const [images, setImages] = useState([]);
  const [quotaLeft, setQuotaLeft] = useState(0);
  const [prompt, setPrompt] = useState("");
  const [isGenerating, setIsGenerating] = useState(false);

  const genImage = async () => {
    setIsGenerating(true);
    await aiGenImage(accessToken, userId, prompt).catch((err) =>
      setIsGenerating(false)
    );
    getImages();
    setIsGenerating(false);
  };

  const getLimits = useCallback(async () => {
    if (!accessToken || !userId) {
      return;
    }
    const limits = await userLimits(accessToken, userId);
    setQuotaLeft(limits.imgGen.limit - limits.imgGen.count);
  }, [accessToken, userId]);

  const getImages = useCallback(async () => {
    setIsLoading(true);
    const newImages = await listAiGenImage(accessToken, userId);
    setImages(newImages);
    setIsLoading(false);
  }, [accessToken, userId]);

  useEffect(() => {
    if (!accessToken || !userId) {
      return;
    }
    getImages();
    getLimits();
  }, [userId, setImages, accessToken, getImages, getLimits]);

  const handleKeyPress = (evt) => {
    if (evt.key === "Enter") {
      evt.preventDefault();
      if (prompt === "") {
        return;
      }
      genImage();
    }
  };

  return (
    <Box>
      <Box sx={{ mt: 8 }}>
        <Typography variant="subtitle2">
          Start with a detailed description (you have {quotaLeft} credits left)
        </Typography>
        <Paper
          component="form"
          sx={{ p: "2px 4px", display: "flex", alignItems: "center" }}
        >
          <InputBase
            value={prompt}
            onKeyDown={handleKeyPress}
            onChange={(evt) => setPrompt(evt.target.value)}
            sx={{ ml: 1, flex: 1 }}
            placeholder="a painting of a fox in the style of Starry Night"
            inputProps={{ "aria-label": "search google maps" }}
            fullWidth
            disabled={isGenerating || quotaLeft < 1}
          />
          <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
          <Button
            color="primary"
            sx={{ p: "10px" }}
            aria-label="directions"
            onClick={genImage}
            disabled={isGenerating || prompt === "" || quotaLeft < 1}
          >
            Generate
          </Button>
        </Paper>
      </Box>
      <Box sx={{ mt: 6 }}>
        {(isLoading || isGenerating || showSkeletons) && <MySkeletons />}
        {images.map(({ prompt, images, _id }) => (
          <ImageList
            key={_id}
            prompt={prompt}
            images={images}
            onClick={onClick}
          />
        ))}
      </Box>
    </Box>
  );
}

export default Images;
