import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import SubmitIcon from "@mui/icons-material/KeyboardReturn";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import { useSearchParams } from "react-router-dom";

import { checkInvite } from "../../api/ckxIn";

export default function Invitation() {
  const [searchParams] = useSearchParams();
  const [isChecking, setIsChecking] = useState(false);
  const [value, setValue] = useState();
  const [error, setError] = useState(null);

  const state = searchParams.get("state");
  const sessionToken = searchParams.get("session_token");

  const handleKeyPress = (evt) => {
    if (evt.key === "Enter") {
      evt.preventDefault();
      handleSubmit();
    }
  };

  const handleSubmit = async () => {
    setIsChecking(true);
    await checkInvite({
      state,
      sessionToken,
      inviteCode: value,
    })
      .then(({ redirect }) => {
        window.location.replace(redirect);
        setError(null);
      })
      .catch(() => {
        setError("Invalid invite code");
        setIsChecking(false);
      });
  };

  return (
    <Box sx={{ width: 400 }}>
      <Paper
        component="form"
        sx={{ p: "2px 4px", display: "flex", alignItems: "center", width: 400 }}
      >
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder="Enter your invite code"
          value={value}
          onChange={(evt) => setValue(evt.target.value)}
          onKeyDown={handleKeyPress}
          disabled={isChecking}
        />
        <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
        <IconButton
          color="primary"
          sx={{ p: "10px" }}
          aria-label="directions"
          onClick={handleSubmit}
          disabled={isChecking}
        >
          <SubmitIcon />
        </IconButton>
      </Paper>
      {error !== null && (
        <Alert severity="error" sx={{ m: 2 }}>
          {error}
        </Alert>
      )}
    </Box>
  );
}
