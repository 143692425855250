import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Routes, Route, Navigate } from "react-router-dom";

import AuthenticationGuard from "./components/authenticationGuard";

import LoggedInLayout from "./layouts/loggedIn";
import PublicLayout from "./layouts/public";
import ProfilePage from "./pages/profile";
import ContentPage from "./pages/content";
import ContentDiscoveryPage from "./pages/contentDiscovery";
import ContentSearchPage from "./pages/contentSearch";
import InvitationPage from "./pages/invitation";
import ContentWizardPage from "./pages/contentWizard";
import ChatsPage from "./pages/chats";
import ChatPage from "./pages/chat";
import HomePage from "./pages/home";
import ChatToPostPage from "./pages/chatToPost";
import ArticleSearchPage from "./pages/articleSearch";
import ImagesPage from "./pages/images";
import CreatePost from "./pages/createPost";

const Layout = () => {
  const { user, logout, isAuthenticated } = useAuth0();
  if (isAuthenticated) {
    return (
      <LoggedInLayout
        user={user}
        logout={logout}
        isAuthenticated={isAuthenticated}
      />
    );
  }
  return <PublicLayout />;
};

export default function Router() {
  const { user, getAccessTokenSilently, isAuthenticated } = useAuth0();
  const [accessToken, setAccessToken] = useState(null);

  console.log("Using: ", process.env.REACT_APP_API_BASE);

  useEffect(() => {
    const getAccessToken = async () => {
      try {
        const myAccessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: `ckxIn - server`,
            scope: "read:messages",
          },
        });
        setAccessToken(myAccessToken);
        return myAccessToken;
      } catch (e) {
        console.log(e);
      }
    };
    getAccessToken();
  }, [getAccessTokenSilently, user?.sub]);

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route
          index
          element={
            isAuthenticated ? <Navigate replace to={"/posts"} /> : <HomePage />
          }
        />
        <Route path="invitation" element={<InvitationPage />} />
        <Route
          path="wizard/content/*"
          element={
            <AuthenticationGuard
              component={ContentWizardPage}
              accessToken={accessToken}
            />
          }
        />
        <Route
          path="profile"
          element={
            <AuthenticationGuard
              component={ProfilePage}
              accessToken={accessToken}
            />
          }
        />
        <Route
          path="content/search"
          element={
            <AuthenticationGuard
              component={ContentSearchPage}
              accessToken={accessToken}
            />
          }
        />
        <Route
          path="content/recommended"
          element={
            <AuthenticationGuard
              component={ContentDiscoveryPage}
              accessToken={accessToken}
            />
          }
        />
        <Route
          path="content/:contentId"
          element={
            <AuthenticationGuard
              component={ContentPage}
              accessToken={accessToken}
            />
          }
        />

        <Route
          path="articles/search"
          element={
            <AuthenticationGuard
              component={ArticleSearchPage}
              accessToken={accessToken}
            />
          }
        />
        <Route
          path="posts"
          element={
            <AuthenticationGuard
              component={ChatsPage}
              accessToken={accessToken}
            />
          }
        />
        <Route
          path="posts/create"
          element={
            <AuthenticationGuard
              component={CreatePost}
              accessToken={accessToken}
            />
          }
        />
        <Route
          path="posts/:chatId"
          element={
            <AuthenticationGuard
              component={ChatToPostPage}
              accessToken={accessToken}
            />
          }
        />
        <Route
          path="chats/:chatId"
          element={
            <AuthenticationGuard
              component={ChatPage}
              accessToken={accessToken}
            />
          }
        />
        <Route
          path="images/"
          element={
            <AuthenticationGuard
              component={ImagesPage}
              accessToken={accessToken}
            />
          }
        />
        {/* <Route path="*" element={<404Page />} /> */}
      </Route>
    </Routes>
  );
}
