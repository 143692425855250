import React from "react";

import Moment from "react-moment";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const DetailPaper = styled(Paper)`
  padding: 10px;
`;
const DeetName = ({ text }) => <Typography variant="h6">{text}</Typography>;
const TextDeetVal = ({ text }) => <Typography>{text}</Typography>;

const TextDetail = ({ name, value, sizes = { xs: 12 } }) => (
  <Grid item {...sizes}>
    <DetailPaper elevation={0}>
      <DeetName text={name} />
      <TextDeetVal text={value} />
    </DetailPaper>
  </Grid>
);
// const LargeTextDetail = ({ name, value, sizes = { xs: 12 } }) => (
//   <Grid item {...sizes}>
//     <Accordion>
//       <AccordionSummary
//         expandIcon={<ExpandMoreIcon />}
//         aria-controls="panel1a-content"
//         id="panel1a-header"
//       >
//         <DeetName text={name} />
//       </AccordionSummary>
//       <AccordionDetails>
//         <TextDeetVal text={value} />
//       </AccordionDetails>
//     </Accordion>
//   </Grid>
// );
const TextArrayDetail = ({ name, value, sizes = { xs: 12 } }) => (
  <Grid item {...sizes} sx={{ width: "100%", height: "100%" }}>
    <DetailPaper elevation={0}>
      <Box sx={{ mb: 1 }}>
        <DeetName text={name} />
      </Box>
      <Stack direction="column" spacing={1}>
        {value &&
          value.map((v) => (
            <Chip key={`keyword-${v}`} label={v} variant="outlined" />
          ))}
        {!value && <TextDeetVal text={`No ${name}`} />}
      </Stack>
    </DetailPaper>
  </Grid>
);

function Content({
  title,
  description,
  pubDate,
  language,
  link,
  source_id,
  keywords,
  category,
  // content,
}) {
  return (
    <Box>
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <TextDetail name="Title" value={title} />
        <TextDetail name="Description" value={description} />
      </Grid>
      {/* <Box sx={{ mb: 2 }}>
        <LargeTextDetail name="Content" value={content} />
      </Box> */}
      <Accordion sx={{ mb: 2 }} expanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <DeetName text={"Content details"} />
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2} sx={{ mb: 2 }}>
            <TextDetail
              name="Source"
              value={source_id}
              sizes={{ xs: 12, sm: 4 }}
            />
            <TextDetail
              name="Language"
              value={language}
              sizes={{ xs: 12, sm: 4 }}
            />
            <TextDetail
              name="Published On"
              value={<Moment format="YYYY/MM/DD">{pubDate}</Moment>}
              sizes={{ xs: 12, sm: 4 }}
            />
            <TextDetail
              name="Link"
              value={
                <a href={link} target="_blank" rel="noopener noreferrer">
                  {link}
                </a>
              }
            />
            <TextArrayDetail
              name="Category"
              value={category}
              sizes={{ md: 12, lg: 6 }}
            />
            <TextArrayDetail
              name="Keywords"
              value={keywords}
              sizes={{ md: 12, lg: 6 }}
            />
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}

export default Content;
