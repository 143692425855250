import React from "react";

import { getChat, newChatMessage, updateChat } from "../../api/ckxIn";

class ContentByIdContainer extends React.Component {
  state = {
    status: "fetching",
    chat: null,
    error: null,
  };

  getChat() {
    this.setState({ status: "fetching" });
    const { accessToken, userId, chatId } = this.props;

    // getLiAuthLink(accessToken).then((resp) => console.log(resp));

    return getChat(accessToken, userId, chatId)
      .then((chat) => {
        this.setState({ status: "ready", error: null, chat });
      })
      .catch((err) => {
        if (err.response.status === 410) {
          return;
        }
        this.setState({ error: err, status: "error" });
      });
  }

  handleNewMessage = async (message) => {
    const { accessToken, userId, chatId } = this.props;
    const newChat = await newChatMessage(accessToken, userId, chatId, message);
    this.setState({ chat: newChat });
  };

  handleUpdate = async (changes) => {
    const { accessToken, userId, chatId } = this.props;
    this.setState({ status: "updating" });
    const chat = await updateChat(accessToken, userId, chatId, changes);
    this.setState({ status: "ready", chat });
  };

  componentDidMount() {
    this.getChat();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.chatId !== this.props.chatId) {
      this.getChat();
    }
  }

  render() {
    const { children, childProps } = this.props;
    if (!children) {
      return null;
    }
    return children({
      ...childProps,
      ...this.state,
      onNewMessage: this.handleNewMessage,
      update: this.handleUpdate,
      newChat: this.newChat,
    });
  }
}

export default ContentByIdContainer;
