import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

import Joyride, { STATUS } from "react-joyride";

import { Outlet } from "react-router-dom";

const steps = [
  { text: "Select an article", id: "my-first-step" },
  { text: "AI Assistant", id: "my-second-step" },
  { text: "Final edits", id: "my-third-step" },
];

export default function HorizontalLinearStepper() {
  const { contentId = null, chatIdx = null } = useParams();
  const [showFtux, setFtux] = useState(false);

  useEffect(() => {
    const shownFtux = localStorage.getItem("ftux-wizard-initial");
    if (!shownFtux) {
      setFtux(true);
    }
  }, [setFtux]);

  const activeStep = contentId === null ? 0 : chatIdx === null ? 1 : 2;
  return (
    <>
      <Joyride
        continuous={true}
        run={showFtux}
        callback={(data) => {
          const { status } = data;
          if ([STATUS.FINISHED].includes(status)) {
            localStorage.setItem("ftux-wizard-initial", true);
          }
        }}
        steps={[
          {
            target: "#stepperRoot",
            content: "aicurate is a three step process",
          },
          {
            target: "#my-first-step",
            content: "Find a relavent article!",
          },
          {
            target: "#my-second-step",
            content:
              "Use the AI assistant to create engaging LinkedIn (all social media in the future) posts!",
          },
          {
            target: "#my-third-step",
            content:
              "Some final manual UI edits and then post straight to LinkedIn!",
          },
          {
            target: "#searchRoot",
            placement: "right-start",
            content:
              "set the parameters for the articles you are interested in",
          },
          {
            target:
              "#searchResults > div > div > div:nth-child(1) > div > div > div > button",
            content:
              "find an article you like and talk to the AI assistant about it",
          },
        ]}
      />
      <Box sx={{ width: "100%", mb: 3, mt: 1 }} id="stepperRoot">
        <Stepper activeStep={activeStep}>
          {steps.map(({ text, id }) => {
            const stepProps = {};
            const labelProps = {};
            return (
              <Step key={id} id={id} {...stepProps}>
                <StepLabel {...labelProps}>{text}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </Box>
      <Outlet />
    </>
  );
}
