import React from "react";
import axios from "axios";

import CssBaseline from "@mui/material/CssBaseline";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import Router from "./router";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const MyAlert = () => {
  const [error, setError] = React.useState(null);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setError(null);
  };

  React.useEffect(() => {
    axios.interceptors.response.use(
      function (response) {
        // Optional: Do something with response data
        return response;
      },
      function (error) {
        setError(error);
        return Promise.reject(error);
      }
    );
  }, [setError]);

  if (error === null) {
    return null;
  }

  return (
    <Snackbar
      open={error !== null}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
        {error.message}
      </Alert>
    </Snackbar>
  );
};

const App = () => {
  return (
    <>
      <CssBaseline />
      <Router />
      <MyAlert />
    </>
  );
};

export default App;
