import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import Joyride, { STATUS } from "react-joyride";

import { getContentChatByContentId, postLinkedin } from "../../api/ckxIn";

import EditPost from "../../components/editPost";

export default function ContentPage({ accessToken }) {
  const navigate = useNavigate();
  const { contentId = null, chatIdx = null } = useParams();
  const [isLoading, setisLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [value, setValue] = useState("");
  const [post, setPost] = useState(null);
  const [showFtux, setFtux] = useState(false);

  useEffect(() => {
    const shownFtux = localStorage.getItem("ftux-wizard-editPost");
    if (!shownFtux) {
      setFtux(true);
    }
  }, [setFtux]);

  useEffect(() => {
    const getChat = async () => {
      setisLoading(true);
      const { messages } = await getContentChatByContentId(
        accessToken,
        contentId
      );
      setValue(messages[chatIdx].content);
      setisLoading(false);
    };
    if (contentId && chatIdx) {
      getChat();
    }
  }, [contentId, chatIdx, accessToken]);

  const handleSubmitPost = async (newPost) => {
    setPost(newPost);
    setConfirm(true);
  };

  const handleConfirmSubmit = async () => {
    setIsSubmitting(true);
    await postLinkedin(accessToken, "changeMe", contentId, post);
    navigate(`/content/${contentId}`);
  };

  return (
    <>
      <Joyride
        continuous={true}
        run={showFtux}
        callback={(data) => {
          const { status } = data;
          if ([STATUS.FINISHED].includes(status)) {
            localStorage.setItem("ftux-wizard-editPost", true);
          }
        }}
        steps={[
          {
            target: "#editPostRoot",
            content: "make some last minute manual edits",
          },
          {
            target: ".changePostImage",
            content: "add an image to make your post pop",
          },
          {
            target: ".submitPost",
            content: "finally post it to LinkedIn",
          },
        ]}
      />

      <Box id="editPostRoot">
        <EditPost
          value={value}
          onChange={setValue}
          onSubmit={handleSubmitPost}
          disabled={isLoading || isSubmitting || confirm}
        />
      </Box>

      <Dialog open={confirm} onClose={() => setConfirm(false)}>
        <DialogTitle>{"Post to your linkedIn profile?"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            This action will post the content straight to your LinkedIn profile
            and be publicly visable.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirm(null)} disabled={isSubmitting}>
            Cancel
          </Button>
          <Button
            onClick={handleConfirmSubmit}
            autoFocus
            color="success"
            variant="contained"
            disabled={isSubmitting}
          >
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
