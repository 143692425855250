import React from "react";
import Box from "@mui/material/Box";
import { useAuth0 } from "@auth0/auth0-react";

import ImageGalleryBlock from "../../blocks/imageGallery";

function Images({ accessToken }) {
  const {
    user: { sub: userId },
  } = useAuth0();

  return (
    <Box>
      <ImageGalleryBlock accessToken={accessToken} userId={userId} />
    </Box>
  );
}

export default Images;
