import React from "react";

import { Routes, Route } from "react-router-dom";

import Layout from "./layout";
import SearchPage from "./searchPage";
import AssistantPage from "./assistantPage";
import PostEditPage from "./postEditPage";

export default function ContentWizardRouter({ accessToken }) {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<SearchPage accessToken={accessToken} />} />
        <Route
          path=":contentId/:chatIdx"
          element={<PostEditPage accessToken={accessToken} />}
        />
        <Route
          path=":contentId"
          element={<AssistantPage accessToken={accessToken} />}
        />
      </Route>
    </Routes>
  );
}
