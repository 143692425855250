import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";

import ContentSearchContainer from "../../containers/contentSearch";
import ContentPreviewList from "../../components/contentPreviewList";
import ContentDetail from "../../components/content";
import ContentSearch from "../../components/contentSearch";

import Grid from "@mui/material/Grid";

export default function SearchPage({ accessToken }) {
  const navigate = useNavigate();
  const [filter, setFilter] = useState({});
  const [detailContent, setDetailContent] = useState(null);

  const handleView = (content) => setDetailContent(content);
  const handleFilterSubmit = (newFilter) => setFilter(newFilter);
  const handleSelected = (content) => {
    navigate(content._id);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3} id="searchRoot">
          <ContentSearch onSubmit={handleFilterSubmit} />
        </Grid>
        <Grid item xs={12} sm={9} id="searchResults">
          {accessToken && (
            <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
              <ContentSearchContainer accessToken={accessToken} filter={filter}>
                {({ status, content }) => (
                  <ContentPreviewList
                    contents={content}
                    onView={handleView}
                    onSelect={handleSelected}
                  />
                )}
              </ContentSearchContainer>
            </Box>
          )}
        </Grid>
      </Grid>
      {detailContent !== null && (
        <Dialog onClose={() => setDetailContent(null)} open={true}>
          <DialogContent>
            <Box sx={{ p: 1 }}>
              <ContentDetail {...detailContent} />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDetailContent(null)}>Close</Button>
            <Button
              onClick={() => setDetailContent(null)}
              autoFocus
              variant="contained"
              color="primary"
            >
              Select
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
