import axios from "axios";
import md5 from "../../helpers/md5";

import getConfig from "../../config";

const { api_base: apiBase, api_cache: apiCache } = getConfig();

function post(accessToken, resource, body) {
  const ep = `${apiBase}${resource}`;
  return axios
    .post(ep, body, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
    .then(({ data }) => data);
}

function put(accessToken, resource, body) {
  const ep = `${apiBase}${resource}`;
  return axios
    .put(ep, body, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
    .then(({ data }) => data);
}

function get(accessToken, resource, allowCache = true) {
  if (!accessToken) {
    throw new Error("No access token");
  }
  const ep = `${apiBase}${resource}`;
  const cacheId = md5(ep);

  const timestampMillis = Date.now();
  const timestampSeconds = Math.floor(timestampMillis / 1000);

  // if (allowCache) {
  //   const sCachedItem = localStorage.getItem(cacheId);
  //   if (sCachedItem) {
  //     const cachedItem = JSON.parse(sCachedItem);
  //     if (cachedItem.expires >= timestampSeconds) {
  //       return cachedItem.content;
  //     }
  //     localStorage.removeItem(cacheId);
  //   }
  // }

  return axios
    .get(ep, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
    .then(({ data }) => {
      if (allowCache) {
        localStorage.setItem(
          cacheId,
          JSON.stringify({ content: data, expires: timestampSeconds + 60 })
        );
      }
      return data;
    });
}

export const getSuggestedContent = async (accessToken) =>
  get(accessToken, "/users/suggested-content", apiCache);

export const getSearchContent = async (accessToken, filter) => {
  var params = Object.keys(filter)
    .map((key) => {
      return key + "=" + filter[key];
    })
    .join("&");
  return get(accessToken, `/content/search?${params}`, apiCache);
};

export const getContent = async (accessToken) =>
  get(accessToken, "/users/content", apiCache);

export const checkInvite = async (props) => post(null, "/invitation/", props);

export const getContentById = async (accessToken, id) =>
  get(accessToken, `/content/${id}`, apiCache);

export const getContentChatByContentId = async (accessToken, contentId) =>
  get(accessToken, `/content/${contentId}/chat`, false);

export const getLinkedInPost = async (accessToken, userId, contentId) =>
  get(
    accessToken,
    `/users/${userId}/content/${contentId}/post/LinkedIn`,
    false
  );

export const createContentChat = async (accessToken, contentId) =>
  post(accessToken, `/content/${contentId}/chat`, {});

export const postLinkedin = async (accessToken, userId, chatId, data) =>
  post(accessToken, `/chats/${userId}/${chatId}/post/linkedin`, data);

export const createChat = async (
  accessToken,
  userId,
  { contentId = null, topic = null, tone = null }
) => post(accessToken, `/chats/${userId}`, { contentId, tone, topic });

export const listChats = async (accessToken, userId) =>
  get(accessToken, `/chats/${userId}`);

export const getChat = async (accessToken, userId, chatId) =>
  get(accessToken, `/chats/${userId}/${chatId}`);

export const newChatMessage = async (accessToken, userId, chatId, message) =>
  post(accessToken, `/chats/${userId}/${chatId}/message`, { message });

export const updateChat = async (accessToken, userId, chatId, changes) =>
  put(accessToken, `/chats/${userId}/${chatId}`, changes);

export const createContentChatComment = async (
  accessToken,
  contentId,
  chatId,
  message
) =>
  post(accessToken, `/content/${contentId}/chat/${chatId}/comment`, {
    message,
  });

export const listAiGenImage = async (accessToken, userId) =>
  get(accessToken, `/images/${userId}`);

export const aiGenImage = async (accessToken, userId, prompt) =>
  post(accessToken, `/images/${userId}`, { prompt });

export const userLimits = async (accessToken, userId) =>
  get(accessToken, `/users/${userId}/limits`);

export const getLiAuthLink = async (accessToken) =>
  get(accessToken, `/linkedin/get-link`);
