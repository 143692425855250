import React from "react";
import { Outlet } from "react-router-dom";
import { styled } from "@mui/system";
import Container from "@mui/material/Container";

import Img1 from "./img/1.jpg";
import Img2 from "./img/2.jpg";
import Img3 from "./img/3.jpg";
import Img4 from "./img/4.jpg";
import Img5 from "./img/5.jpg";

const MyContainer = styled(Container)({
  padding: 50,
  height: "fit-content",
  marginTop: "-25%",
});

// const LightContainer = styled(MyContainer)({
//   backgroundColor: "rgba(200, 200, 200, 0.4)",
// });
const DarkContainer = styled(MyContainer)({
  backgroundColor: "rgba(20, 20, 20, 0.4)",
  color: "white",
});

const BgRoot = styled("div")({
  height: "100vh",
  minHeight: "100%",
  backgroundPosition: "center",
  backgroundSize: "cover",
  display: "flex",
  alignItems: "center",
});

const Images = [Img1, Img2, Img3, Img4, Img5];
const ImgCont = [
  DarkContainer,
  DarkContainer,
  DarkContainer,
  DarkContainer,
  DarkContainer,
];

const LoggedIn = () => {
  const randImg = Math.floor(Math.random() * Images.length);
  const BgImg = styled(BgRoot)({
    backgroundImage: `url(${Images[randImg]})`,
  });
  const Cont = ImgCont[randImg];

  return (
    <BgImg>
      <Cont maxWidth="lg">
        <Outlet />
      </Cont>
    </BgImg>
  );
};

export default LoggedIn;
