import { withAuthenticationRequired } from "@auth0/auth0-react";
import React from "react";
import PageLoader from "../pageLoader";

const AuthenticationGuard = ({ component, accessToken }) => {
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => (
      <div>
        <PageLoader />
      </div>
    ),
  });

  return <Component accessToken={accessToken} />;
};

export default AuthenticationGuard;
