import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";

import { useNavigate } from "react-router-dom";

const pages = [
  // { display: "Find articles", link: "/articles/search" },
  { display: "Posts", link: "/posts" },
  { display: "Images", link: "/images" },
  // { display: "Recommended Content", link: "/content/recommended" },
  // { display: "Search for Content", link: "/content/search" },
];
// const settings = [{ display: "Profile", link: "/profile" }];
const settings = [];

function MyAppBar({ user, logout, isAuthenticated }) {
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    handleCloseUserMenu();
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters sx={{ justifyContent: "space-between" }}>
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              cursor: "pointer",
              alignItems: "center",
              mr: 5,
            }}
            onClick={() =>
              isAuthenticated ? navigate("/posts") : navigate("/")
            }
          >
            <img src="/logo.png" alt="aicurate main logo" height={55} />
          </Box>

          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map(({ display, link }) => (
                <MenuItem
                  key={`pages-${link}`}
                  onClick={() => {
                    handleCloseNavMenu();
                    navigate(link);
                  }}
                >
                  <Typography textAlign="center">{display}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Box
            sx={{
              display: { xs: "flex", md: "none" },
              cursor: "pointer",
              alignItems: "center",
            }}
            onClick={() =>
              isAuthenticated ? navigate("/posts") : navigate("/")
            }
          >
            <img
              src="/favicon-32x32.png"
              height={32}
              alt="aicurate main logo"
            />
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {pages.map(({ display, link }) => (
              <Button
                key={`pages-${link}`}
                onClick={() => {
                  handleCloseNavMenu();
                  navigate(link);
                }}
                sx={{ my: 2, color: "white", display: "block" }}
              >
                {display}
              </Button>
            ))}
          </Box>

          <Box>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                {/* <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" /> */}
                <Avatar alt={user.name} src={user.picture} />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map(({ display, link }) => (
                <MenuItem
                  key={`setting-link`}
                  onClick={() => {
                    handleCloseUserMenu();
                    navigate(link);
                  }}
                >
                  <Typography textAlign="center">{display}</Typography>
                </MenuItem>
              ))}
              <MenuItem onClick={handleLogout}>
                <Typography textAlign="center">Logout</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default MyAppBar;
