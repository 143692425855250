import React, { useEffect, useState, useRef, useCallback } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { useParams, useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import ContentByIdContainer from "../../containers/contentById";
import ContentChatContainer from "../../containers/contentChat";
import Content from "../../components/content";
import ContentChat from "../../components/chat";
import EditPost from "../../components/editPost";
import LinkedInPost from "../../components/linkedInPost";

import { postLinkedin, getLinkedInPost } from "../../api/ckxIn";

function ContentDetails({
  cachedContent,
  contentId,
  accessToken,
  onHaveContent,
}) {
  useEffect(() => {
    if (cachedContent !== null) {
      window.scrollTo(0, 0); // if we are here it was via useNavigate so need to scroll to top
    }
  }, [cachedContent]);

  if (cachedContent !== null) {
    const cont = JSON.parse(cachedContent);
    onHaveContent(cont);
    return <Content {...cont} />;
  }
  return (
    <ContentByIdContainer id={contentId} accessToken={accessToken}>
      {({ status, content }) => {
        if (status === "ready") {
          onHaveContent(content);
        }
        return (
          <>
            {status === "fetching" && <div>loading.....</div>}
            {status === "ready" && <Content {...content} />}
          </>
        );
      }}
    </ContentByIdContainer>
  );
}

function ContentPage({ accessToken }) {
  const location = useLocation();
  const { content: cachedContent = null } = location?.state || {};
  const { contentId } = useParams();
  const [liPost, setLiPost] = useState("");
  const [content, setContent] = useState(null);
  const [disableEdit, setDisableEdit] = useState(false);
  const [linkedInPost, setLinkedInPost] = useState(null);
  const [sendPost, setSendPost] = useState(null);
  const { isLoading } = useAuth0();

  const linkedInPostRef = useRef(null);

  const updateLinkedInPost = useCallback(async () => {
    if (!accessToken) {
      return;
    }
    const myLinkedInPost = await getLinkedInPost(
      accessToken,
      "blah",
      contentId
    );
    setLinkedInPost(myLinkedInPost);
  }, [accessToken, contentId]);

  useEffect(() => {
    updateLinkedInPost();
  }, [updateLinkedInPost]);

  if (isLoading || !accessToken) {
    return <div>Loading ...</div>;
  }

  const handleSocialSelect = (social, message) => {
    switch (social) {
      case "linkedIn":
        setLiPost({
          text: message + " " + content.link,
          link: content.link,
          image: content.image,
        });
        linkedInPostRef.current.scrollIntoView();
        break;
      default:
        console.log("no handler for " + social);
    }
  };

  const handleSubmitPort = async () => {
    setDisableEdit(true);
    await postLinkedin(accessToken, "changeMe", contentId, sendPost);
    setLinkedInPost(false);
    await updateLinkedInPost();
    setSendPost(null);
  };

  return (
    <>
      <Box sx={{ mb: 3 }}>
        <ContentDetails
          accessToken={accessToken}
          cachedContent={cachedContent}
          contentId={contentId}
          onHaveContent={(newContent) => setContent(newContent)}
        />
        {linkedInPost === null && (
          <>
            <Divider variant="middle" sx={{ mb: 2 }} />
            <Typography variant="h4" gutterBottom>
              Assistant
            </Typography>
            <ContentChatContainer
              accessToken={accessToken}
              contentId={contentId}
              childProps={{ onSocialSelect: handleSocialSelect }}
            >
              {({
                status,
                contentChat,
                createChat,
                newChat,
                onSocialSelect,
              }) => {
                if (status !== "ready") {
                  return <Typography>Loading....</Typography>;
                }
                if (!contentChat) {
                  return (
                    <Button onClick={createChat}>Start a conversation</Button>
                  );
                }
                return (
                  <ContentChat
                    chat={contentChat}
                    onNewChat={newChat}
                    onSocialSelect={onSocialSelect}
                  />
                );
              }}
            </ContentChatContainer>
          </>
        )}
        {linkedInPost === null && (
          <>
            <Divider variant="middle" sx={{ mb: 2 }} />
            <Typography variant="h4" gutterBottom>
              Post to socials
            </Typography>
            <EditPost
              refProp={linkedInPostRef}
              name="LinkedIn"
              value={liPost.text}
              image={liPost.image}
              link={liPost.link}
              onChange={(newPost) => setLiPost(newPost)}
              disabled={disableEdit}
              onSubmit={(post) => setSendPost(post)}
            />
          </>
        )}
        {linkedInPost !== null && (
          <>
            <Divider variant="middle" sx={{ mb: 2 }} />
            <Typography variant="h4" gutterBottom>
              LinkedIn Post
            </Typography>
            <LinkedInPost {...linkedInPost} />
          </>
        )}
      </Box>
      <Dialog open={sendPost !== null} onClose={() => setSendPost(null)}>
        <DialogTitle id="alert-dialog-title">
          {"Post to your LinkedIn profile?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            This action will post the content straight to your LinkedIn profile
            and be publicly visable.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSendPost(null)} disabled={disableEdit}>
            Cancel
          </Button>
          <Button
            onClick={handleSubmitPort}
            autoFocus
            color="success"
            variant="contained"
            disabled={disableEdit}
          >
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ContentPage;
