import React from "react";
import { styled } from "@mui/system";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ContentIcon from "@mui/icons-material/Article";
import ChitChatIcon from "@mui/icons-material/Chat";
import Moment from "react-moment";
import Stack from "@mui/material/Stack";
import Skeleton from "@mui/material/Skeleton";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import Tooltip from "@mui/material/Tooltip";
import moment from "moment";

import { indigo, teal } from "@mui/material/colors";
import ListChatLabels from "../listChatLabels";

const MyListItemText = styled(ListItemText)({});

const MySkeleton = () => (
  <ListItem sx={{ width: "100%" }}>
    <ListItemIcon>
      <Skeleton variant="rounded" height={25} width={25} />
    </ListItemIcon>
    <Stack sx={{ width: "100%" }} spacing={1}>
      <Skeleton variant="rectangular" height={25} width="95%" />
      <Skeleton variant="rectangular" height={20} width="25%" />
    </Stack>
  </ListItem>
);

const ContentChat = ({ chat, onClick }) => (
  <ListItemButton onClick={() => onClick(chat)}>
    <ListItemIcon>
      <ContentIcon sx={{ color: teal[400] }} />
    </ListItemIcon>
    <MyListItemText
      primary={chat.content.title}
      secondary={
        <>
          <Moment fromNow>{chat.uat}</Moment>
          <ListChatLabels labels={chat.labels} />
        </>
      }
    />
  </ListItemButton>
);

const ChitChat = ({ chat, onClick }) => {
  let myChat = chat.messages || [];
  myChat = myChat.filter((i) => i.role === "system");

  const chatTitle = myChat.messages?.length
    ? myChat.messages[0].content
    : "No chat yet";
  return (
    <ListItemButton onClick={() => onClick(chat)}>
      <ListItemIcon>
        <ChitChatIcon sx={{ color: indigo[400] }} />
      </ListItemIcon>
      <MyListItemText
        primary={chat.topic || chatTitle}
        secondary={
          <>
            <Moment fromNow>{chat.uat}</Moment>
            <ListChatLabels labels={chat.labels} />
          </>
        }
      />
      {chat.liPost && (
        <Tooltip
          title={`Posted ${moment(chat.liPost.uat).format(
            "dddd, MMMM Do YYYY, h:mm:ss a"
          )}`}
        >
          <LinkedInIcon />
        </Tooltip>
      )}
    </ListItemButton>
  );
};

function ListChats({ chats, onClick, status }) {
  return (
    <>
      <List sx={{ width: "100%", bgcolor: "background.paper" }}>
        {status === "fetching" && (
          <>
            <MySkeleton />
            <MySkeleton />
            <MySkeleton />
            <MySkeleton />
            <MySkeleton />
            <MySkeleton />
            <MySkeleton />
            <MySkeleton />
          </>
        )}
        {status === "ready" &&
          chats.map((chat) =>
            chat.content ? (
              <ContentChat chat={chat} onClick={onClick} />
            ) : (
              <ChitChat chat={chat} onClick={onClick} />
            )
          )}
      </List>
    </>
  );
}

export default ListChats;
