import React from "react";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Divider";
import Moment from "react-moment";

function LinkedInPost({ post, iat }) {
  return (
    <Paper sx={{ p: 2, display: "flex", alignItems: "center" }}>
      <InputBase
        sx={{ width: "100% !important" }}
        multiline
        minRows={4}
        value={post}
        readOnly
      />
      <Divider sx={{ m: 1 }} orientation="vertical" variant="middle" flexItem />
      <Box sx={{ width: 200 }}>
        <Moment fromNow>{iat}</Moment>
      </Box>
    </Paper>
  );
}

export default LinkedInPost;
