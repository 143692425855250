import * as React from "react";
import Moment from "react-moment";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

export default function ContentPreview(props) {
  const {
    content: { title, description, source_id, image, pubDate },
    onSelect,
    onView,
  } = props;

  return (
    <Card sx={{ width: "100%" }}>
      <CardHeader
        title={title}
        subheader={<Moment format="MMMM Do YYYY">{pubDate}</Moment>}
      />
      {image && (
        <CardMedia
          component="img"
          height="194"
          image={image}
          alt="Paella dish"
        />
      )}
      <CardContent>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          {source_id}
        </Typography>
        <Typography variant="body2">{description}</Typography>
      </CardContent>
      <CardActions>
        {onView && (
          <Button size="small" onClick={() => onView(props.content)}>
            Detail
          </Button>
        )}
        <Box sx={{ marginLeft: "auto" }}>
          {onSelect && (
            <Button
              onClick={() => onSelect(props.content)}
              variant="contained"
              color="primary"
              size="small"
            >
              Create Post
            </Button>
          )}
        </Box>
      </CardActions>
    </Card>
  );
}
