import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import Box from "@mui/material/Box";
import CreateIcon from "@mui/icons-material/Create";
import Button from "@mui/material/Button";

import ListChats from "../../components/listChats";

import { listChats } from "../../api/ckxIn";

function Posts({ accessToken }) {
  const navigate = useNavigate();
  const [chats, setChats] = useState([]);
  const [loading, setLoading] = useState(false);
  const {
    user: { sub: userId },
  } = useAuth0();

  const getPosts = useCallback(async () => {
    setLoading(true);
    if (!accessToken) {
      return null;
    }
    const myPosts = await listChats(accessToken, userId);

    if (myPosts.length === 0) {
      navigate(`/posts/create`);
    }

    setChats(myPosts);
    setLoading(false);
  }, [accessToken, userId, navigate]);

  useEffect(() => {
    if (!userId) {
      return;
    }
    getPosts();
  }, [userId, getPosts]);

  const handleCreateChat = async () => {
    navigate(`/posts/create`);
  };

  const handleClick = (chat) => {
    navigate(`/posts/${chat._id}`);
  };

  return (
    <Box>
      <Button
        startIcon={<CreateIcon />}
        onClick={handleCreateChat}
        disabled={!userId && !loading}
        variant="outlined"
        color="secondary"
      >
        Create Post
      </Button>
      <ListChats
        chats={chats}
        onClick={handleClick}
        status={loading ? "fetching" : "ready"}
      />
    </Box>
  );
}

export default Posts;
