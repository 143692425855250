import React, { useState, useRef } from "react";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SubmitIcon from "@mui/icons-material/KeyboardReturn";
import EmojiIcon from "@mui/icons-material/EmojiEmotions";
import AddImageIcon from "@mui/icons-material/Image";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import EmojiPicker from "emoji-picker-react";

import ImgSel from "../../components/imageSelector";

function EditPost({
  name,
  value,
  onChange,
  onSubmit,
  refProp,
  disabled,
  image,
  accessToken,
  userId,
}) {
  const inputRef = useRef(null);
  const [dispEmoji, setDispEmoji] = useState(false);
  const [dispAddImage, setDispAddImage] = useState(false);
  const [myImage, setMyImage] = useState(null);

  const handleSubmit = () => {
    if (onSubmit) {
      onSubmit({
        text: value,
        image: myImage || image || null,
      });
    }
  };
  const handleChange = (evt) => {
    if (onChange) {
      onChange(evt.target.value);
    }
  };
  const toggleAddImage = () => {
    setDispAddImage(!dispAddImage);
  };
  const handleImgSubmit = (newImg) => {
    setMyImage(newImg);
    toggleAddImage();
  };

  const insertEmoji = (emojiObject) => {
    if (value.trim() === "") {
      return;
    }

    const textarea = inputRef.current;
    const cursorPosition = textarea.selectionEnd;
    const start = value.substring(0, textarea.selectionStart);
    const end = value.substring(textarea.selectionStart);
    const text = start + emojiObject.emoji + end;
    onChange(text);

    //Codes added for the new cursor
    const newCursor = cursorPosition + emojiObject.emoji.length;
    setTimeout(
      () => inputRef.current.setSelectionRange(newCursor, newCursor),
      10
    );
  };

  return (
    <>
      <Card sx={{ width: "100%", maxWidth: 400, margin: "auto" }} ref={refProp}>
        {image && myImage === null && (
          <CardMedia
            component="img"
            width="100%"
            image={image}
            alt="Paella dish"
          />
        )}
        {myImage && (
          <CardMedia
            component="img"
            height="194"
            image={myImage}
            sx={{ objectFit: "contain" }}
          />
        )}

        <CardContent>
          <>
            <InputBase
              sx={{ width: "100% !important" }}
              placeholder={"Share your thoughts on LinkedIn......"}
              label={name}
              multiline
              minRows={4}
              value={value}
              onChange={handleChange}
              disabled={disabled}
              inputRef={inputRef}
            />
          </>
        </CardContent>
        <CardActions>
          <IconButton
            onClick={() => setDispEmoji(!dispEmoji)}
            color="primary"
            sx={{ marginLeft: "auto" }}
            aria-label="display emoji selector"
            disabled={disabled}
          >
            <EmojiIcon />
          </IconButton>
          <IconButton
            onClick={toggleAddImage}
            color="primary"
            sx={{ marginLeft: "auto" }}
            aria-label="change post image"
            disabled={disabled}
            className="changePostImage"
          >
            <AddImageIcon />
          </IconButton>
          <IconButton
            onClick={handleSubmit}
            color="primary"
            sx={{ marginLeft: "auto" }}
            aria-label="submit to linkedIn"
            className="submitPost"
            disabled={value === "" || value === null || disabled}
          >
            <SubmitIcon />
          </IconButton>
        </CardActions>
      </Card>
      {dispEmoji && (
        <Box
          sx={{
            marginTop: 15,
            width: "100%",
            maxWidth: 600,
            ml: "auto",
            mr: "auto",
            mt: 1,
          }}
        >
          <EmojiPicker onEmojiClick={insertEmoji} width={"100%"} />
        </Box>
      )}
      <Dialog onClose={toggleAddImage} open={dispAddImage}>
        <DialogTitle>Change post image</DialogTitle>
        <Box sx={{ p: 3 }}>
          <ImgSel
            onCancel={toggleAddImage}
            onSubmit={handleImgSubmit}
            accessToken={accessToken}
            userId={userId}
          />
        </Box>
      </Dialog>
    </>
  );
}

export default EditPost;
