import React from "react";
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import ChatComponent from "../../components/chat";
import ChatContainer from "../../containers/chat";

function Chat({ accessToken }) {
  const { chatId } = useParams();

  const {
    user: { sub: userId },
  } = useAuth0();

  return (
    <Box>
      <ChatContainer accessToken={accessToken} userId={userId} chatId={chatId}>
        {({ status, chat, onNewMessage }) => {
          if (status !== "ready") {
            return <Typography>Loading....</Typography>;
          }
          return (
            <ChatComponent
              chat={chat}
              onNewMessage={onNewMessage}
              showTyping={true}
            />
          );
        }}
      </ChatContainer>
    </Box>
  );
}

export default Chat;
