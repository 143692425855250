import React from "react";

import { getSearchContent } from "../../api/ckxIn";

class ContentSeach extends React.Component {
  state = {
    status: "fetching",
    content: [],
    error: null,
  };

  getContent() {
    this.setState({ status: "fetching" });
    const { accessToken, filter } = this.props;
    return getSearchContent(accessToken, filter)
      .then((content) =>
        this.setState({ content, error: null, status: "ready" })
      )
      .catch((err) =>
        this.setState({ content: [], error: err, status: "error" })
      );
  }

  componentDidMount() {
    this.getContent();
  }

  componentDidUpdate(prevProps, prevState) {
    const { filter } = this.props;
    if (filter !== prevProps.filter) {
      this.getContent();
    }
  }

  render() {
    const { children } = this.props;
    if (!children) {
      return null;
    }
    return children(this.state);
  }
}

export default ContentSeach;
