import React from "react";

import { getSuggestedContent } from "../../api/ckxIn";

class ContentContainer extends React.Component {
  state = {
    status: "fetching",
    content: [],
  };

  async getSuggestedContent() {
    const { accessToken } = this.props;
    const content = await getSuggestedContent(accessToken);
    this.setState({ content: content.map((c) => c.content) });
  }

  componentDidMount() {
    this.getSuggestedContent();
  }

  render() {
    const { children } = this.props;
    if (!children) {
      return null;
    }
    return children(this.state);
  }
}

export default ContentContainer;
