import * as React from "react";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";

import LabelSelect from "./labelSelect";

const TestLabelList = [
  "AI",
  "Climate",
  "Quantum",
  "Agriculture",
  "Sustainability",
];

function ListChatLabels({
  labels = [],
  labelList = TestLabelList,
  onDelete = null,
  onAdd = null,
  disabled = false,
}) {
  const filteredLabelList = labelList.filter((l) => !labels.includes(l));
  return (
    <Stack direction="row" spacing={1} sx={{ alignItems: "center" }}>
      {onAdd && (
        <LabelSelect
          onChange={onAdd}
          labelList={filteredLabelList}
          disabled={disabled}
        />
      )}
      {labels.map((l) => (
        <Chip
          key={l}
          label={l}
          variant="outlined"
          onDelete={!disabled && onDelete ? () => onDelete(l) : null}
        />
      ))}
    </Stack>
  );
}

export default ListChatLabels;
