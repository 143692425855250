import React from "react";

import Grid from "@mui/material/Grid";

import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import ContentPreview from "../contentPreview";

const MySkeleton = () => (
  <Card sx={{ width: "100%" }}>
    <CardContent>
      <Stack spacing={1}>
        <Skeleton variant="rectangular" height={30} width="95%" />
        <Skeleton variant="rectangular" height={30} width="80%" />
        <Skeleton variant="rectangular" height={30} width="85%" />
        <Skeleton variant="rectangular" height={15} width="60%" />

        <Skeleton variant="rectangular" height={160} />
        <Skeleton variant="rectangular" height={15} width="90%" />
        <Skeleton variant="rectangular" height={15} width="95%" />
        <Skeleton variant="rectangular" height={15} width="680" />
      </Stack>
    </CardContent>
  </Card>
);

const MySkeletons = () => (
  <>
    <Grid item xs={12} sm={6}>
      <MySkeleton />
    </Grid>
    <Grid item xs={12} sm={6}>
      <MySkeleton />
    </Grid>
    <Grid item xs={12} sm={6}>
      <MySkeleton />
    </Grid>
    <Grid item xs={12} sm={6}>
      <MySkeleton />
    </Grid>
  </>
);

export default function ContentPreviewList({
  contents,
  onView,
  onSelect,
  status,
}) {
  return (
    <>
      <Grid container spacing={2} sx={{ mt: 0 }}>
        {status === "fetching" && <MySkeletons />}
        {status === "ready" &&
          contents.length > 0 &&
          contents.map((c) => (
            <Grid item xs={12} sm={6} key={`contentList-${c._id}`}>
              <ContentPreview content={c} onView={onView} onSelect={onSelect} />
            </Grid>
          ))}
        {status === "ready" && contents.length === 0 && (
          <Box sx={{ m: 3 }}>No articles were found</Box>
        )}
      </Grid>
    </>
  );
}
