import React from "react";
import { useNavigate } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";

import getConfig from "./config";

function Auth0ProviderWithNavigate({ children }) {
  const navigate = useNavigate();

  const {
    auth0_domain: domain,
    auth0_clientId: clientId,
    auth0_audience: audience,
    auth0_scope: scope,
  } = getConfig();

  const onRedirectCallback = (appState) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  if (!(domain && clientId && audience && scope)) {
    return <div>Authentication not configured</div>;
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: audience,
        scope: scope,
      }}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
}

export default Auth0ProviderWithNavigate;
