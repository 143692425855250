import React from "react";
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import ChatToPostBlock from "../../blocks/chatToPost";

function ChatToPost({ accessToken }) {
  const { chatId } = useParams();

  const {
    user: { sub: userId },
  } = useAuth0();

  return (
    <ChatToPostBlock
      accessToken={accessToken}
      chatId={chatId}
      userId={userId}
    />
  );
}

export default ChatToPost;
