import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";

const filter = createFilterOptions();

function LabelInput({ onChange = null, labelList = [], disabled }) {
  return (
    <Autocomplete
      disabled={disabled}
      onChange={(event, newValue) => {
        if (onChange && newValue) {
          const regTest = /Add "(.*)"/.exec(newValue);
          if (regTest) {
            newValue = regTest[1];
          }
          onChange(newValue);
        }
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        if (filtered.length === 0 && params.inputValue !== "") {
          filtered.push(`Add "${params.inputValue}"`);
        }
        return filtered;
      }}
      handleHomeEndKeys
      options={labelList}
      sx={{ width: 200 }}
      freeSolo
      renderInput={(params) => <TextField {...params} label="Add label" />}
    />
  );
}

export default LabelInput;
